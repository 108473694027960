<template>
  <el-container style="height:calc(100vh);">
    <el-header>
      <span class="left el-icon-arrow-left" @click="returnOn">员工信息</span>
      员工详情
    </el-header>
    <el-main class="main">

      <div class="content">
        <div class="cell-s">
          <div class="title">
            <img :src="$getimgsrc(info.avatar)" alt="">
            <div>
              <div class="name"> {{  info.realname}} </div>
              <div class="tel">{{  info.mobile}}</div>
            </div>
          </div>
          <div class="value">
            {{ info.manypersonrole_id }}
          </div>
        </div>
        <div class="staff-box">
          <div class="cell">
            <div class="cell-y">
              <div class="title">
                今日累计
              </div>
              <div class="value">
                <b>¥ {{  info.today}}</b>
              </div>
            </div>
            <div class="cell-y">
              <div class="title">
                本月累计
              </div>
              <div class="value">
                <b>¥ {{ info.month }}</b>
              </div>
            </div>
          </div>
          <div class="cell-y" @click="jumpBillsWater">
            <div class="title">
              账单流水
            </div>
            <div class="value">
              <span class="el-icon-arrow-right"></span>
            </div>
          </div>
          <div class="cell-y" @click="jumpSuccession">
            <div class="title">
              交班记录
            </div>
            <div class="value">
              <span class="el-icon-arrow-right"></span>
            </div>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { mapState } from 'vuex' 
  export default {
    data() {
      return {
        // info:{}
      }
    },
    computed:{
      ...mapState({info:'staff'})
    },
    methods: {
      // 返回上级
      returnOn() {
        this.$router.push({
          name: 'StaffInfo'
        })
      },
      jumpBillsWater() {
        this.$router.push({
          name: 'BillsWater'
        })
      },
      jumpSuccession() {
        this.$router.push({
          path: '/succession',
          query:{
            id:this.info.id
          }
        })
      },

    }
  };

</script>
<style lang="less" scoped>
  .el-header {
    position: relative;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .left {
      display: flex;
      align-items: center;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12px;
      color: #666;
      line-height: 19px;

      &::before {
        color: #46a6ff;
        font-size: 18px;
        line-height: 18px;
      }
    }
  }

  .main {
    background-color: #f9f9f9;
  }

  .content {
    padding: 20px;

    .cell-s {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      background-color: #fff;
      color: #666;
      margin-bottom: 15px;
      border: 1px solid #f2f2f2;
      border-radius: 5px;


      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
          border-radius: 50%;
        }

        .name,
        .tel {
          font-size: 12px;
          line-height: 20px;
        }
      }

      .value {
        width: 100px;
        line-height: 30px;
        text-align: center;
        border: 1px solid #999;
        border-radius: 5px;
      }
    }

    .staff-box {
      .cell {
        padding: 0 15px;
        margin-bottom: 20px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #f2f2f2;


        .cell-y {
          margin-bottom: 0;
          border: none;
          border-bottom: 1px solid #f2f2f2;

          &:last-child {
            border-bottom: none;
          }
        }


      }

      .cell-y {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid #f2f2f2;

        color: #666;
        background-color: #fff;
        margin-bottom: 20px;
        border-radius: 5px;


        .value {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .el-icon-arrow-right {
            margin-left: 20px;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
  }

</style>
